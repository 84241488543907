// 所有页面路由统一管理
const iot = [{
    path: '/',
    // name: 'home',
    component: resolve => require(['@/components/homeHeader'], resolve),
    redirect: '/home', // 路由重定向
    children: [
        { path: '/home', component: resolve => require(['@/views/Home'], resolve) },
        
        // { path: '/homeMb', component: resolve => require(['@/views/HomeMb'], resolve) },
        
        // 空白页
        { path: '/back', component: resolve => require(['@/views/back'], resolve) },
        { path: '/back2', component: resolve => require(['@/views/back2'], resolve) },
        { path: '/load', component: resolve => require(['@/views/load'], resolve) },
        // 搜索结果
        { path: '/search', component: resolve => require(['@/views/search'], resolve) },
        // 检测领域
        { path: '/detection', component: resolve => require(['@/views/detection/detection'], resolve) },
        { path: '/detectionDetail', component: resolve => require(['@/views/detectionDetail/detectionDetail'], resolve) },
        // 质检服务
        { path: '/quality', component: resolve => require(['@/views/quality'], resolve) },
        { path: '/qualityDetail', component: resolve => require(['@/views/qualityDetail'], resolve) },
        // 认证中心
        { path: "/AC", component: resolve => require(['@/views/AC.vue'], resolve) },
        { path: "/AC2", component: resolve => require(['@/views/AC2.vue'], resolve) },
        { path: "/ACDetail", component: resolve => require(['@/views/ACDetail.vue'], resolve) },
        { path: "/ACDetail2", component: resolve => require(['@/views/ACDetail2.vue'], resolve) },
        // 合作案例
        { path: '/cooperation', component: resolve => require(['@/views/cooperation'], resolve) },
        { path: '/cooperationDetail', component: resolve => require(['@/views/cooperationDetail'], resolve) },
        // 国联动态
        { path: '/enterprise', name:"Enterprise", component: resolve => require(['@/views/enterprise'], resolve) },
        { path: '/enterpriseDetail', component: resolve => require(['@/views/enterpriseDetail'], resolve) },
        // 质检科普
        { path: '/popularization', component: resolve => require(['@/views/popularization'], resolve) },
        { path: '/popularizationDetail', component: resolve => require(['@/views/popularizationDetail'], resolve) },
        // 关于我们  aboutMeDetail
        { path: '/aboutMe', component: resolve => require(['@/views/aboutMe'], resolve) },
        { path: '/aboutMeDetail', component: resolve => require(['@/views/aboutMeDetail'], resolve) },
        // 投资者关系
        { path: '/investors', component: resolve => require(['@/views/investors'], resolve) },
        { path: '/investorsDetail', component: resolve => require(['@/views/investorsDetail'], resolve) },
        // 联系我们
        { path: '/contact', component: resolve => require(['@/views/contact'], resolve) },
        // 隐私政策
        { path: '/privacy', component: resolve => require(['@/views/privacy'], resolve) },
        { path: '/privacy2', component: resolve => require(['@/views/privacy2'], resolve) },
        { path: '/privacy3', component: resolve => require(['@/views/privacy3'], resolve) },
        { path: '/privacy4', component: resolve => require(['@/views/privacy4'], resolve) },
        // 业务服务
        { path: '/services', component: resolve => require(['@/views/businessServices/index.vue'], resolve) },
        // 实用工具
        { path: '/utilitytools', component: resolve => require(['@/views/utilityTools/index.vue'], resolve) },
        { path: '/toolsDetail', component: resolve => require(['@/views/utilityTools/detail.vue'], resolve) },
        //标准套餐
        { path: '/standardSetMeal', component: resolve => require(['@/views/standardSetMeal'], resolve) },
        { path: '/partyBuilding', component: resolve => require(['@/views/partyBuilding'], resolve) },
        { path: '/queryOrder', component: resolve => require(['@/views/queryOrder'], resolve) },
        { path: '/orderList', component: resolve => require(['@/views/orderList'], resolve) },
        { path: '/orderDetails', component: resolve => require(['@/views/orderDetails'], resolve) },
        { path: '/pdfview', component: resolve => require(['@/views/pdfview'], resolve) },
        { path: '/menus', component: resolve => require(['@/views/menus'], resolve) },
        { path: '/activty', component: resolve => require(['@/views/activty'], resolve) },
        { path: '/mactivty', component: resolve => require(['@/views/mactivty'], resolve) },
        // 国联党建
        { path: '/partybuildingDetail', component: resolve => require(['@/views/partybuildingDetail'], resolve) },
        // 实验室
        { path: '/laboratory', component: resolve => require(['@/views/laboratory/laboratory'], resolve) },
        { path: '/laboratorySearch', component: resolve => require(['@/views/laboratory/laboratorySearch'], resolve) }
        ]
}, ]

export default iot
