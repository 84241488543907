export const onlineConsulting = () =>{
    const specs="height=600,width=1000,alwaysRaised,directories=no,toolbar=no,titlebar=no,menubar=no,scrollbars=no,left=300,top = 200, resizable=no,location=no, status=no"
    window.open("https://tb.53kf.com/code/client/5cd60bbe34341ac131de2c0cddeef983/4","_blank",specs)
}

export const onlineConsultingHalf = () =>{

    var _53 = $53.createApi();  		/*开发者文档1.1 创建SDK资源对象*/
    _53.push('cmd', 'kfclient');    /*开发者文档1.2 打开pc访客端*/
    _53.push('type', 'popup');
    _53.query();
    // window.open('https://tb.53kf.com/code/client/697fe4b606954006525234d9cfc6bea78/4','','height=499,width=712,top=0,left=0,toolbar=no,menubar=yes,scrollbars=yes,resizable=yes,location=no,status=no')
}
export const onlineMobileConsultingHalf = () =>{
    var _53 = $53.createApi();      /*开发者文档1.1 创建SDK资源对象*/
    _53.push('cmd', 'mtalk');       /*开发者文档1.7 主动发起(手机端)*/
    _53.query();
    // window.open('https://tb.53kf.com/code/client/697fe4b606954006525234d9cfc6bea78/4','','height=499,width=712,top=0,left=0,toolbar=no,menubar=yes,scrollbars=yes,resizable=yes,location=no,status=no')
}

export const hideFooter = () =>{
    document.querySelector('.footers').style.display='none'
}
export const showFooter = () =>{
    document.querySelector('.footers').style.display='flex'
}

export const shwoFooters = () =>{

    let element = document.getElementById("footer-operator-app");
    element.style.display = 'flex'
}
export const cholseFooters= () =>{
    let element = document.getElementById("footer-operator-app");
    element.style.display = 'none'
}


