import axios from '@/http'
import getUrl from './path'
const id = "0";
const formUrl = 'http://zty.xaunqd.com/topic'
const iotHome = {
  /**
   *
   *
   *  apiName(data){ // api--effect
   *    return axios({
   *      method:'GET',POST or GET
   *      url: getUrl('apiName'),
   *      data:data //get为params:data
   *    })
   *  },
   *  this.$api.iotHome.apiName(data).then(res=>{})
   */

  list(data) {
    // 产品列表--
    data.siteId = id
    return axios({
      method: 'GET',
      url: getUrl('/web/product/list'),
      params: data,
    })
  },

  searchProductList(data) {
    return axios({
      method: 'GET',
      url: getUrl(`/web/searchProduct`),
      params: data,
    })
  },
  hotProductlist(data) {
    // 产品列表--
    data.siteId = id
    return axios({
      method: 'GET',
      url: getUrl('/web/product/getHotSettingProduct'),
      params: data,
    })
  },
  detail(data, index) {
    //产品详情信息--
    data.siteId = id
    return axios({
      method: 'GET',
      url: getUrl('/web/product/detail/' + index),
      params: data,
    })
  },
  typelist(data) {
    //获取产品类别列表
    data.siteId = id
    return axios({
      method: 'GET',
      url: getUrl('/web/product/typeList'),
      params: data,
    })
  },
  columnList(data) {
    //栏目列表
    data.siteId = id
    return axios({
      method: 'GET',
      url: getUrl('/web/cms/columnList'),
      params: data,
    })
  },
  getArticleByCode(data) {
    // 根据文章代号获取文章信息
    data.siteId = id
    return axios({
      method: 'GET',
      url: getUrl('/web/cms/getArticleByCode'),
      params: data,
    })
  },
  getArticleById(data) {
    // 根据文章编号获取详细信息
    data.siteId = id
    return axios({
      method: 'GET',
      url: getUrl('/web/cms/getArticleById'),
      params: data,
    })
  },
  getArticleList(data) {
    // 文章列表的接口
    data.siteIds = id
    return axios({
      method: 'GET',
      url: getUrl('/web/cms/getArticleList'),
      params: data,
    })
  },
  getArticleLists(data) {
    // 文章列表的接口
    return axios({
      method: 'GET',
      url: getUrl('/web/cms/getArticleList'),
      params: data,
    })
  },
  getBanner(data) {
    // banner图片--
    data.siteId = id
    return axios({
      method: 'GET',
      url: getUrl('/web/cms/getBanner'),
      params: data,
    })
  },
  getFieList(data) {
    // 获取资料列表信息--
    data.siteId = id
    return axios({
      method: 'GET',
      url: getUrl('/web/cms/getFieList'),
      params: data,
    })
  },
  getImageList(data) {
    // 获取图片列表信息--
    data.siteId = id
    return axios({
      method: 'GET',
      url: getUrl('/web/cms/getImageList'),
      params: data,
    })
  },
  getSite(data) {
    // 根据站点类别获取站点基本信息--
    return axios({
      method: 'GET',
      url: getUrl('/web/cms/getSite'),
      params: data,
    })
  },
  getNoticeList(data) {
    // 获取通知公告列表信息
    data.siteId = id
    return axios({
      method: 'GET',
      url: getUrl('/web/cms/getNoticeList'),
      params: data,
    })
  },
  saveMessage(data) {
    // saveMessage-----消息
    return axios({
      method: 'POST',
      url: getUrl('/web/cms/saveMessage'),
      data: data,
    })
  },
  friendLink(data) {
    // 友情链接
    return axios({
      method: 'GET',
      url: getUrl('/web/cms/friendLink'),
      params: data,
    })
  },
  searchProduct(data) {
    data.siteId = id
    return axios({
      method: 'GET',
      url: getUrl('/web/product/searchProduct'),
      params: data,
    })
  },
  getSearch(data) {
    return axios({
      method: 'GET',
      url: getUrl('/web/search'),
      params: data,
    })
  },
  getHomeInfo(data) {
    return axios({
      method: 'GET',
      url: getUrl('/web/home/getHomeInfo'),
      params: data,
    })
  },
  getTdk(data) {
    data.siteId = id
    return axios({
      method: 'GET',
      url: getUrl('/web/home/getTdk'),
      params: data,
    })
  },
  getKeywords(data) {
    data.siteId = id
    return axios({
      method: 'GET',
      url: getUrl('/web/cms/getKeywords'),
      params: data,
    })
  },
  downloadFile(data) {
    return axios({
      method: 'GET',
      url: getUrl('/web/cms/download'),
      params: data,
      responseType: 'blob',
    })
  },
  getSiteList(data) {
    //所有站点信息
    return axios({
      method: 'GET',
      url: getUrl('/web/cms/getSiteList'),
      params: data,
    })
  },
  getServiceList(data) {
    return axios({
      method: 'GET',
      url: getUrl('/web/cms/findService'),
      params: data,
    })
  },
  getIndustrySolutionList(data) {
    return axios({
      method: 'GET',
      url: getUrl('/web/product/productSolveCaseList'),
      params: data,
    })
  },
  getCategoryGroupList(data) {
    return axios({
      method: 'GET',
      url: getUrl('/web/product/getCategoryGroupList'),
      params: data,
    })
  },
  getLaboratoryImageList(data) {
    return axios({
      method: 'GET',
      // url: getUrl('/web/cms/getLaboratoryImage?columnId=112001'),
      url: getUrl('/web/cms/getLaboratoryImage?columnId=10719'),
      params: data,
    })
  },
  consultFrom(data) {
    return axios({
      method: 'POST',
      url: getUrl('/web/sales/add'),
      data: data,
    })
  },
  productClassifyDetail(data) {
    return axios({
      method: 'GET',
      url: getUrl('/web/product/getTypeInfoByParentId'),
      params: data,
    })
  },
  aboutrecommendList(data) {
    return axios({
      method: 'GET',
      url: getUrl('/web/product/recommendProductList'),
      params: data,
    })
  },
  loggingData(data) {
    return axios({
      method: 'POST',
      url: getUrl('/web/searchRecord'),
      data,
    })
  },
  getHotProductList(data) {
    return axios({
      method: 'GET',
      url: getUrl('web/product/productList'),
      params: data,
    })
  },
  getStandardPackages(data) {
    return axios({
      method: 'GET',
      url: getUrl('/web/product/getStandardPackages'),
      params: data,
    })
  },
  getOrderListQuery(params, token) {
    return axios({
      headers: {
        'order-token': token,
      },
      method: 'GET',
      url: getUrl('/web/order/orderListQuery'),
      params,
    })
  },
  confirmGoods(data) {
    return axios({
      method: 'POST',
      url: getUrl('/web/order/confirmGoods'),
      data,
    })
  },
  sendValidateCode(data) {
    return axios({
      method: 'POST',
      url: getUrl('/web/order/sendValidateCode'),
      data,
    })
  },
  validateCode(data) {
    return axios({
      method: 'POST',
      url: getUrl('/web/order/validateCode'),
      data,
    })
  },
  downloadPdfFile(url) {
    return axios({
      method: 'GET',
      url: url,
      responseType: 'blob',
    })
  },
  topicTool(id) {
    return axios({
      method: 'get',
      url: formUrl + `/sys/topicTool/info?topicNo=${id}`,
    })
  },
  topicToolClueAdd(data) {
    return axios({
      method: 'POST',
      url: 'http://mc.xaunqd.com/topic' + `/sys/topicToolClue/add`,
      data,
    })
  },
  commonDict(data) {
    return axios({
      method: 'POST',
      url: formUrl + `/sys/common/auth/commonDict/list`,
      data,
    })
  },
  mobileTypeList(data) {
    return axios({
      method: 'GET',
      url: getUrl('/web/product/mobileTypeList'),
      data,
    })
  },
  mobileTypeProductList(data) {
    return axios({
      method: 'GET',
      url: getUrl(`/web/product/mobileTypeProductList?id=${data.id}`),
      data,
    })
  },
  // 视频接口
  videoApi(data) {
    return axios({
      method: 'GET',
      url: getUrl(`/web/product/laboratory?id=${data.id}`),
      data,
    })
  },
/**
 * 联合实验室信息
 * @returns 联合实验室信息
 */
  getUnitedLaboratoryList() { 
    return axios({
      method: 'GET',
      url: getUrl('/web/gjl/laboratory/image')
    })
  },
  /**
   * 实验室搜索
   * @param {*} detectionTypeId 检测类型ID
   * @param {*} detectionAreaId 地域ID
   * @returns 实验室搜索list
   */
  findLaboratoryDataByParams(detectionTypeId, detectionAreaId) {
    return axios({
      method: 'GET',
      url: getUrl('/web/gjl/laboratory/search?detectionTypeId=' + detectionTypeId + '&detectionAreaId=' + detectionAreaId )
    })
  },
  /**
   * 查询实时样品列表
   * @returns 查询实时样品列表
   */
  findRealTimeSampleData () {
    return axios({
      method: 'GET',
      url: getUrl('/web/gjl/sample/list')
    })
  },
  /**
   * 特色自有实验室列表
   * @param {*} type 自有实验室
   * @returns 特色自有实验室列表
   */
  findUniqueOwnerData (type) {
    return axios({
      method: 'GET',
      url: getUrl('/web/gjl/laboratory/owner/list?type=' + type)
    })
  },
  /**
   * 获取友情链接
   * @returns 友情链接
   */
  getFriendLinkData () {
    return axios({
      method: 'GET',
      url: getUrl('/web/gjl/friend/link')
    })
  },
  /**
   * 获取新闻动态
   * @param {*} page 页码
   * @param {*} rows 每页数量
   * @returns 新闻动态
   */
  findDynamicNewsData (page, rows) {
    return axios({
      method: 'GET',
      url: getUrl('/web/cms/getArticleList?page=' + page + '&rows=' + rows + '&columnId=10301&siteIds=0')
    })
  },
  /**
   * 获取检测类型及地域
   * @returns 检测类型及地域
   */
   getDetectionAndConditionData () {
    return axios({
      method: 'GET',
      url: getUrl('/web/gjl/detection/condition')
    })
  },
  /**
   * 获取移动端一级分类列表
   * @returns 移动端一级分类列表
   */
   getMoblieTypeData () {
    return axios({
      method: 'GET',
      url: getUrl('/web/gjl/type/list')
    })
  },
  /**
   * 获取行业资讯
   * @param {*} page 页码
   * @param {*} rows 每页数量
   * @returns 行业资讯
   */
   findNewsIndustryData (page, rows) {
    return axios({
      method: 'GET',
      url: getUrl('/web/cms/getArticleList?page=' + page + '&rows=' + rows + '&columnId=10302&siteIds=0')
    })
  },
   /**
   * 获取资质列表
   * @returns 资质列表
   */
    getQualificationData (page, rows) {
      return axios({
        method: 'GET',
        url: getUrl('/web/gjl/qualification/list?page=' + page + '&rows=' + rows)
      })
    },
    /**
   * 获取产品ID（用于请求相关推荐产品）
   * @returns 资质列表
   */
     getProductId () {
      return axios({
        method: 'GET',
        url: getUrl('/web/gjl/productId/random')
      })
    },
    /**
   * 联合实验室图片列表
   * @param {*} type 自有实验室
   * @returns 特色自有实验室列表
   */
    getUniteLaboratoryImageList(type) {
      let url = '/web/cms/getLaboratoryImage?columnId=10719'
      if(type){
        url = url +'&type='+type
      }
      return axios({
        method: 'GET',
        url: getUrl(url),
      })
  },
}



export default iotHome
